import { nanoid } from '@reduxjs/toolkit'
import { compraPaquete } from 'actions/administracion'
import { ReactComponent as Down } from 'assets/down-chevron.svg'
import CircularProgress from 'components/CircularProgress/CircularProgress'
import { AnimatePresence, motion } from 'framer-motion'
import { createCardToken, createPayment, createSubscription, encryptCard } from 'helpers/payments'
import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import Cards from 'react-credit-cards'
//import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useGetPersona } from './formHelper'
import { useDispatch } from 'react-redux'
import useUser from 'hooks/useUser'

const ConfirmationModal = ({ setModal, state }) => {
  //const history = useHistory()
  const { payment, account } = state

  return (
    <AnimatePresence>
      <motion.div className="paymentConfirmationModal" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <div className="content">
          <h3>Confirmando su cuenta</h3>

          <div style={{ marginBlock: '.5rem' }}>{(!payment || !account) && <CircularProgress />}</div>

          <div className="phrase">{!payment ? 'Confirmando su método de pago...' : 'Metodo de pago confirmado!'}</div>
          <div className="phrase">{!account && payment ? 'Confirmando su cuenta...' : null}</div>
          <div className="phrase">{account && payment ? 'Cuenta confirmada!' : null}</div>

          {payment && account && (
            <button
              className="btnRegistro"
              onClick={() => {
                //history.push('/')
                setModal(false)
              }}
            >
              Confirmar
            </button>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

const Payment = ({ form, setForm, stepUp, stepDown, handleChange, variants }) => {
  const { plan, pago } = form
  const dispatch = useDispatch()
  const { savetaxInfo } = useUser()
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [confirmationState, setConfirmationState] = useState({
    payment: false,
    account: false,
  })
  const [accepted, setAccepted] = useState(false)
  const getPersona = useGetPersona()
  const navigate = useNavigate()
  const getNombre = () => {
    return plan.nombre
  }

  const handleSubmit = async () => {
    if (!pago?.cvc) {
      toast.error('Ingrese el cvc')
      return
    } else if (!pago?.expiry) {
      toast.error('Ingrese la fecha de vencimiento')
      return
    } else if (!pago?.name) {
      toast.error('Ingrese el nombre del titular de la tarjeta')
      return
    } else if (!pago?.number) {
      toast.error('Ingrese el numero de tarjeta')
      return
    }

    try {
      setConfirmationModal(true)
      const encryptedCard = encryptCard(pago)

      const creditCardToken = await createCardToken(nanoid(), encryptedCard)

      //await createSubscription(creditCardToken.token, plan)

      //setConfirmationState({ ...confirmationState, payment: true })

      //await new Promise((res) => setTimeout(res, 5000))
      //console.log('llego aca')
      const response = await dispatch(
        compraPaquete({
          idPaquete: plan.id,
          numTarjeta: creditCardToken.last_digits,
          vencimiento: pago.expiry,
          holder: creditCardToken.token,
          //cvv: pago.cvc,
          codCia: savetaxInfo?.cia?.codCia,
        })
      )
      if (response) {
        navigate('/escritorio')
        /*if (plan.option === 2) {
          await createSubscription(creditCardToken.token, plan, savetaxInfo?.cia?.ruc)
          setConfirmationState({ ...confirmationState, payment: true })
          setConfirmationModal(false)
          toast.success('Pago Realizado exitosamente!')
          navigate('/escritorio')
        } else {
          await createPayment(creditCardToken.token, plan, savetaxInfo?.cia)
          setConfirmationState({ ...confirmationState, payment: true })
          setConfirmationModal(false)
          toast.success('Pago Realizado exitosamente!')
          navigate('/escritorio')
        }*/
      }
    } catch (e) {
      console.error(e.message)
      setConfirmationState({ ...confirmationState, payment: false })
      setConfirmationModal(false)
      toast.error('Hubo un problema al comprar su paquete')
      return false
    }

    //toast.success('Pago Realizado exitosamente!')
    //navigate('/escritorio')
  }

  return (
    <>
      <motion.div initial={variants.initial} animate={variants.animate} exit={variants.exit} transition={variants.transition}>
        <div className="Form registroForm">
          <div className="carrusel step01">
            <div className="slide">
              <div className="infoPayStep" style={{ width: '50%' }}>
                <div className="steps">
                  <span>Paso</span> <b>2</b> <span>de 2</span>
                </div>
                <h4>
                  <button className="backButton" onClick={stepDown}>
                    <Down />
                  </button>
                  Ingrese su tarjeta
                </h4>

                <div className="cardData" style={{ width: '600px' }}>
                  <div className="cardDataInput">
                    <Cards
                      focused={pago.focus ?? ''}
                      cvc={pago.cvc ?? ''}
                      expiry={pago.expiry ?? ''}
                      name={pago.name ?? ''}
                      number={pago.number ?? ''}
                    />
                    <label htmlFor="nombreTitular" className="text" style={{ width: '100%' }}>
                      Nombre del Titular
                      <input
                        onChange={handleChange}
                        name="pago.name"
                        type="text"
                        value={pago.name}
                        placeholder="Nombre del Titular"
                        id="nombreTitular"
                      />
                    </label>
                    <label htmlFor="numTarjeta" className="text" style={{ width: '50%' }}>
                      Número de Tarjeta
                      <input
                        name="pago.number"
                        value={pago.number ?? ''}
                        onChange={handleChange}
                        type="number"
                        placeholder="Número de Tarjeta"
                        id="numTarjeta"
                      />
                    </label>
                    <label htmlFor="expiry" className="text" style={{ width: '50%' }}>
                      Fecha Vencimiento
                      <input
                        name="pago.expiry"
                        onChange={handleChange}
                        type="month"
                        placeholder="MMYY"
                        id="expiry"
                        maxLength="4"
                        minLength="4"
                        value={pago.expiry ?? ''}
                      />
                    </label>
                    <label htmlFor="cvc" className="text" style={{ width: '50%' }}>
                      CVC
                      <input
                        maxLength="4"
                        name="pago.cvc"
                        value={pago.cvc}
                        onChange={handleChange}
                        type="text"
                        placeholder="CVC"
                        id="cvc"
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div className="resumen">
                <h4>Resumen de la orden:</h4>
                <p>Confirme que la información es correcta y presione el botón para completar el pago de la transacción</p>
                <hr />
                <ul>
                  <li>Plan: {getNombre()}</li>
                  <li>
                    <span>Pago:</span>
                    <label htmlFor="opcionDePago" className="text" style={{ width: '200px' }}>
                      <select value={plan.option} name="plan.option" id="opcionDePago" onChange={handleChange} disabled>
                        <option value={1}>Unico</option>
                        <option value={2}>Mensual</option>
                      </select>
                    </label>
                  </li>
                  <li>Descuento: ₡{0}</li>
                  <li>Cobro: ₡{plan.mensual}</li>
                </ul>
                <hr />
                <p></p>
                <label htmlFor="">
                  <input type="checkbox" checked={accepted} onChange={() => setAccepted((prev) => !prev)} />
                  Acepto los Terminos y Condiciones
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="actionFixed">
          <button className="btnRegistro" onClick={handleSubmit} disabled={!accepted}>
            Confirmar y pagar
          </button>
        </div>
      </motion.div>
      {confirmationModal && <ConfirmationModal setModal={setConfirmationModal} state={confirmationState} />}
    </>
  )
}

export default Payment
